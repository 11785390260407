.djs-minimap {
  position: absolute;
  top: 20px;
  right: 20px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 1px #CCC;
  border-radius: 2px;
  box-sizing: border-box;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.djs-minimap:not(.open) {
  overflow: hidden;
}

.djs-minimap .map {
  display: none;
}

.djs-minimap.open .map {
  display: block;
}

.djs-minimap .map {
  width: 320px;
  height: 180px;
}

.djs-minimap:not(.open) .toggle {
  padding: 10px;
  text-align: center;
}

.djs-minimap .toggle:before {
  content: attr(title);
}

.djs-minimap.open .toggle {
  position: absolute;
  right: 0;
  padding: 6px;
  z-index: 1;
}

.djs-minimap .map {
  cursor: crosshair;
}

.djs-minimap .viewport {
  /* fill: rgba(255, 116, 0, 0.25); */
  fill: none;
  stroke: none;
}

.djs-minimap .viewport-dom {
  position: absolute;
  border: solid 2px orange;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: move;
}

.djs-minimap:not(.open) .viewport-dom {
  display: none;
}

.djs-minimap.open .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}

.djs-minimap .cursor-crosshair {
  cursor: crosshair;
}

.djs-minimap .cursor-move {
  cursor: move;
}
